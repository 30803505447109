<template>
  <div>
    <CRow>
      <CCol sm="12">
        <RoadsTable
          :items="roads"
          :permissions="permissions"
          hover
          striped
          border
          small
          fixed
          caption="Vialidades"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import RoadsTable from '../../components/roads/RoadsTable.vue'
import roads from '../../services/roads';
import store from '../../store'

export default {
  name: 'Roads',
  components: { RoadsTable },
  data: function () {
		return {
            roads: [],
            permissions: []
        }
  },
  mounted: async function() {
    this.loading();

    let response = await roads.get();

    if(response.type == "success"){
      this.roads = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.roads = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
