<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="roadModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="12">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
                <CCol md="12">
                  <CDataTable
                    hover
                    border
                    fixed
                    :items="form.sections"
                    :fields="fields"
                    :items-per-page="30"
                    pagination
                    size="sm"
                    :header="false"
                  >
                    <template #code="{item}"><td class="text_small text-center w100" :style="'background-color: '+(item.status == 'bueno' ? 'green' : (item.status == 'regular' ? 'yellow' : 'red'))+'; color: '+(item.status == 'bueno' ? 'white' : (item.status == 'regular' ? 'black' : 'white'))+';'">{{item.code}}</td></template>
                    <template #name="{item}"><td class="text_small" :style="'background-color: '+(item.status == 'bueno' ? 'green' : (item.status == 'regular' ? 'yellow' : 'red'))+'; color: '+(item.status == 'bueno' ? 'white' : (item.status == 'regular' ? 'black' : 'white'))+';'">{{item.name}}</td></template>
                    <template #status="{item}">
                      <td class="text_small text-center w150" :style="'background-color: '+(item.status == 'bueno' ? 'green' : (item.status == 'regular' ? 'yellow' : 'red'))+'; color: '+(item.status == 'bueno' ? 'white' : (item.status == 'regular' ? 'black' : 'white'))+';'">
                        <select v-model="item.status" style="width: 100%; height: 30px;">
                          <option value="bueno">BUENO</option>
                          <option value="regular">REGULAR</option>
                          <option value="malo">MALO</option>
                        </select>
                      </td>
                    </template>
                  </CDataTable>
                </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Guardar Cambios</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import roles from '../../services/roles';

export default {
  name: 'RoadModal',
  props: {

  },
  data () {
    return {
      roadModal: false,
      title: "Nueva Vialidad",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'code', label: ''},
        {key: 'name', label: ''},
        {key: 'status', label: ''}
      ],
      sections: []
    }
  },
  mounted: async function() {

  },
  methods: {
    storeModal () {
      this.roadModal = true;
      this.color = "info";
      this.title = 'Nueva Vialidad';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          sections: []
      }
    },
    updateModal (item) {
      this.roadModal = true;
      this.color = "warning";
      this.title = 'Editar Vialidad';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          sections: item.sections
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.roadModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      }
    }
  },
}
</script>